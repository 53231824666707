<template>


<div class="container">
    <modal-attendance-muster ref="musterModal" modalId="musterModal" title="Prozivka" :employees="musterWorkers" :date="selected_date" :startTime.sync="autoStartTime" :endTime.sync="autoEndTime" :startTimeLower.sync="autoStartTimeLower"/>
    <modal-attendance-type modalId="modalAttendanceType"/>

    <modal-attendance-map modalId="modalAttendanceMap" />
    <modal-employee-catering modalId="modalEmployeeCatering" :selectedEmployee="selectedCateringEmployee" />

    <div class="table-title">
        <div class="row">
            <div class="col-sm-6 text-white mb-3">
                <h2>Evidencija radnika</h2>
            </div>
            <div class="col-sm-3">
                <div class="form-floating">
                    <input type="date" class="form-control" id="attendenceDate" :disabled="user.role != 'ADMIN'" v-model="selected_date" placeholder="Za datum" required @change="selectedDateChanged">
                    <label for="attendenceDate">Za datum</label>
                    <div class="invalid-feedback">
                        Unesite datum
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-floating">
                    <select type="date" class="form-control" id="woDeleted" v-model="selected_wo" placeholder="Radni nalog" @change="woChanged">
                            <option disabled :value="null">Izaberite radni nalog</option>
                            <template v-if="user.role == 'ADMIN'">
                                <option :value="-1" :key="-1">SVI Nalozi</option>
                                <option v-for="wo in allActiveWorkOrders" :value="wo" :key="wo.id">{{wo.name}}</option>
                            </template>
                            <template v-else>
                                <option v-for="pwo in preWorkOrdersFilterWorking" :value="pwo.work_order_obj" :key="pwo.work_order_obj.id">{{pwo.work_order_obj.name}}</option>
                            </template>
                    </select>
                    <label for="woAgreedValue">Radni nalog</label>
                </div>
            </div>

            
            
        </div>
    </div>

    <div class="row mb-3" v-if="isToday">
        <div class="col-md-2 col-12"><span>Automatsko popunjavanje radnog vremna</span></div>
        <div class="col-md-4">
            <div class="form-floating">
                <input type="time" class="form-control" id="autoStartTime" :max="autoEndTime" v-model="autoStartTime" placeholder="Početak radnog vremena">
                <label for="attendenceDate">Početak radnog vremena</label>
            </div>

        </div>
        <div class="col-md-4 d-flex">
            <button v-if="user.role == 'ADMIN'" type="button" class="btn" data-bs-toggle="modal" data-bs-target="#modalAttendanceType"><font-awesome-icon icon="cog" size="2x"></font-awesome-icon></button>
            <button type="button" class="btn" @click="openModalAttendanceMap"><font-awesome-icon icon="map-marked-alt" size="2x"></font-awesome-icon></button>
        </div>
       

        <!-- <div class="col-md-4 col-6">
            <div class="form-floating">
                <input type="time" class="form-control" id="autoEndTime" :min="autoStartTime" v-model="autoEndTime" placeholder="Kraj radnog vremena">
                <label for="attendenceDate">Kraj radnog vremena</label>
            </div>

        </div> -->
    </div>
    <div v-else>
            <button v-if="user.role == 'ADMIN'" type="button" class="btn" @click="openModalAttendanceMap"><font-awesome-icon icon="map-marked-alt" size="2x"></font-awesome-icon></button>

    </div>

    <div class="row" v-if="selected_wo">
        <template v-if="isToday">
            <div class="col-md-3 mb-3">
                <button class="btn btn-primary btn-lg" @click="startMuster">Započni prozivku</button>
            </div>
            <div class="col-md-3 mb-3">
                <button v-show="!editMode" class="btn btn-outline-primary btn-lg" @click="enableEdit">Izmeni tabelu</button>
            </div>
        </template>
    </div>
    <div v-else>
        <h2 v-if="preWorkOrdersFilterWorking.length > 0">Izaberite radni nalog</h2>
        <h2 v-else>Danas niste nigde upućeni</h2>
    </div>

    <!-- <button @click="printGrid()" class="btn btn-outline-primary">ShowTable</button> -->
    <div>
        <ejs-grid
            ref="workAttendanceGrid"
            :dataSource="filterWorkers" 
            class="work-attendance-grid"
            :allowFiltering='true'
            :allowSorting='true'
            :showColumnMenu='true'
            :filterSettings='filterSettings'
            :allowPaging='false' 
            :pageSettings='pageSettings'
            :recordDoubleClick="workAttendanceDB"
            :allowResizing='true'
            :dataBound='dataBound'
            :childGrid="childGrid"
            :actionFailure='actionFailure'

            >
            <e-columns>

                <e-column field='id' headerText='ID' width='120' textAlign='Right' :visible='false' ></e-column>
                <e-column field='user.first_name' headerText='Ime i Prezime' width='120' textAlign='Left' :template="usernameTemplate"></e-column>
                
                <!-- <e-column field='employee.first_name' headerText='Ime' width='120' textAlign='Left'></e-column>
                <e-column field='employee.last_name' headerText='Prezime' width='120'></e-column> -->

                <e-column field='pwo.work_order_obj.name' headerText='Nalog' width='60' textAlign='Left' :visible='selected_wo === -1'></e-column>
                <e-column field='time_start' headerText='Dolazak' width='80' textAlign='Right' :template="workAttendanceStart" :visible='true'></e-column>
                <e-column field='time_end' headerText='Odlazak' width='80' textAlign='Right' :template="workAttendanceEnd" :visible='true'></e-column>
                <e-column field='work_attendance.catering' headerText='Ketering' width='50' textAlign='Right' :template="workAttendanceCatering" :visible='true'></e-column>

                <e-column headerText='' :visible='true' textAlign='Right' :template="workAttendanceCMD"></e-column>


            </e-columns>
        </ejs-grid> 
    </div>


</div>
</template>

<script>
import {mapState} from 'vuex'
import {mapGetters} from 'vuex'
import UtilService from '@/service/UtilService.js';
import EmployeesService from '@/service/EmployeesService.js';
import WorkAttendanceService from '@/service/WorkAttendanceService.js';
import ModalAttendanceMuster from '@/components/modals/ModalAttendanceMuster.vue';
import { Modal } from 'bootstrap';
import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize, DetailRow, CommandColumn } from "@syncfusion/ej2-vue-grids";

import UsernameTemplate from '@/components/grid-templates/UsernameTemplate.vue';
import WorkAttendanceCMD from '@/components/grid-templates/WorkAttendanceCMD.vue';
import TimesheetTableTemplate from '@/components/work_attendance/TimesheetTableTemplate.vue';

import Vue from 'vue';
import ModalAttendanceType from '@/components/modals/ModalAttendanceType.vue';
import ModalAttendanceMap from '@/components/modals/maps/ModalAttendanceMap.vue';
import CateringService from '@/service/CateringService.js';
import ModalEmployeeCatering from './modals/catering/ModalEmployeeCatering.vue';
import { fromLonLat } from "ol/proj";
import LineString from "ol/geom/LineString";
import {getLength} from 'ol/sphere';



export default {
    components: {ModalAttendanceMuster, ModalAttendanceType, ModalAttendanceMap, ModalEmployeeCatering},




    data() {
        // function timesheetTableCMD() {
        //     return {
        //         template: {
        //             extends: TimesheetTableTemplate,
        //             propsData: {
        //                 column: "CMD"
        //             },
                    
        //         }

        //     };
        // }


        return {
            filterSettings: { type: "Excel" },
            pageSettings: { pageCount: 20},
            editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },

            modalEditAbsebce: null,
            modalAttendanceType: null,
            confirmModal: null,
            modalAttendanceMap: null,
            modalEmployeeCatering: null,

            editedAbsence: null,


            selected_date: UtilService.formatDatePattern(UtilService.getMoment()),
        
            preWorkOrders: [],
            selected_wo: undefined,

            autoStartTime: this.getCurrentDate(),
            autoStartTimeLower: this.getCurrentDate(),
            autoEndTime: "17:00",

            filterLower: "00:00",
            filterUpper: "23:59",
            musterModal: null,
            musterWorkers: [],

            editMode: false,

            showTable: false,

            selectedCateringEmployee: null,

            childGrid: {
                // dataSource: this.timesheets,
                dataSource: [],
                queryString: "employee_id",
                actionFailure: this.actionFailure,
                editSettings: { allowAdding: true, allowDeleting: true, mode: 'Normal' },
                queryCellInfo: this.queryCellInfoTimesheet,

                columns: [
                {
                    field: "id",
                    headerText: "ID",
                    textAlign: "Right",
                    width: 120,
                    visible: false
                },
                { field: "time_start", headerText: "Početak", width: 120, template: this.timesheetTableTemplateStart },
                { field: "time_end", headerText: "Kraj", width: 120, template: this.timesheetTableTemplateEnd },
                { field: "work_order", headerText: "Radni nalog", width: 150, valueAccessor: this.workOrderCellAccessor, template: this.timesheetTableTemplateWO },
                { field: "quota_value", headerText: "Kvota", editType: 'numericedit', width: 150, template: this.timesheetTableTemplateQuote },
                { field: "", headerText: "", width: 50, template: this.timesheetTableTemplateCMD },
                ],
            },

            fakeId: 10000000,

            expandedRowIndex: [],
            
            usernameTemplate: function() {
                return {
                    template: {
                        extends: UsernameTemplate,
                        propsData: {
                            showAvatar: true,
                            showName: true,
                            showLastName: true,
                            showUsername: false,
                            profilePage: 'EmployeeCarnet',
                            role: true,
                            
                        },
                    }

                };
            },
            
            workAttendanceCMD: function() {
                return {
                    template: {
                        extends: WorkAttendanceCMD,
                        propsData: {
                            column: "CMD"
                        },
                        
                    }

                };
            },
            workAttendanceStart: function() {
                return {
                    template: {
                        extends: WorkAttendanceCMD,
                        propsData: {
                            column: "START"
                        },
                        
                    }

                };
            },
            workAttendanceEnd: function() {
                return {
                    template: {
                        extends: WorkAttendanceCMD,
                        propsData: {
                            column: "END"
                        },
                        
                    }

                };
            },

            workAttendanceCatering: function() {
                return {
                    template: {
                        extends: WorkAttendanceCMD,
                        propsData: {
                            column: "CATERING"
                        },
                        
                    }

                };
            },


        }
    },

    provide: {
        grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize, DetailRow, CommandColumn ]
    },

    computed: {
        ...mapGetters([
            'isAdmin'
        ]),

        ...mapState([
            'user',
            'userAssignment',
            'allWorkOrders',
            'workAttendanceEmployees'
        ]),

        filterWorkers() {
            const self = this;
            let filteredWorkers = this.workAttendanceEmployees.filter(w => {
                if (w?.work_end_date < self.selected_date && !(w?.work_attendance?.length > 0)){
                    return false;
                }
                if (w.absence) return false

                return true;
            });

            this.workAttendanceEmployees.forEach(element => {
                element.employee_id = element.id;
            });

            return filteredWorkers;

        },


        timesheets() {
            let timesheets = []
            this.workAttendanceEmployees.forEach(wa => {
                wa.employee_id = wa.id;
                wa?.work_attendance?.timesheet?.forEach(ts => {
                    
                    ts.employee_id = wa.id;
                    timesheets.push(ts);

                });

            });

            return timesheets
        },

        isToday() {
            return this.selected_date == UtilService.formatDatePattern(UtilService.getMoment())
        },

        todayDate() {
            return UtilService.formatDatePattern(UtilService.getMoment())
        },

        allActiveWorkOrders() {
            return this.allWorkOrders.filter(x => !x.real_end_date || x.real_end_date > this.todayDate)
        },

        preWorkOrdersFilterWorking() {
            if (this.pwo_role == 1 || this.user.role == 'WORKER') return [this.userAssignment?.pwo]
            return this.preWorkOrders.filter(x => x.id && x.work_order?.name != "").filter(x => !x.real_end_date || x.real_end_date > this.todayDate);
        },

        pwo_role() {
            return this.userAssignment?.pwo?.pwo_role;
        },
        

    },

    methods: {
        formatDate : UtilService.formatDate,
        formatDateTime : UtilService.formatDateTime,

        getLocationFullindex : UtilService.getLocationFullindex,
        getPositionFullindex : UtilService.getPositionFullindex,
        fullNameList : UtilService.fullNameList,
        absenceTypeFormat: UtilService.absenceTypeFormat,



            
        timesheetTableTemplateCMD() {
            return {
                template: {
                    extends: TimesheetTableTemplate,
                    propsData: {
                        column: "CMD",
                        parent: this,
                    },
                    
                }

            };
        },

        timesheetTableTemplateStart() {
            return {
                template: {
                    extends: TimesheetTableTemplate,
                    propsData: {
                        column: "START",
                        parent: this,
                    },
                    
                }

            };
        },
            
        timesheetTableTemplateEnd() {
            return {
                template: {
                    extends: TimesheetTableTemplate,
                    propsData: {
                        column: "END",
                        parent: this,
                    },
                    
                }

            };
        },
            
        timesheetTableTemplateWO() {
            return {
                template: {
                    extends: TimesheetTableTemplate,
                    propsData: {
                        column: "WO",
                        parent: this,
                    },
                    
                }

            };
        },
            
        timesheetTableTemplateQuote() {
            return {
                template: {
                    extends: TimesheetTableTemplate,
                    propsData: {
                        column: "QUOTE",
                        parent: this,
                    },
                    
                }

            };
        },

        queryCellInfoTimesheet(args) {
            let data = args.data;
            if (data.addRow === true && args.column.field === "time_start") {
                args.colSpan = 10
            }

            if (data.deleted) {
                args.cell.classList.add('cell-deleted');
            }
        },

        workOrderCellAccessor(field, data) {
            if (field == "key") return data.key;

            return data?.work_order_obj?.name
        },


        async selectedDateChanged() {
            let oldSelect = this.selected_wo;

            await this.loadPreWorkOrders();
            if (oldSelect == this.selected_wo) this.woChanged();
            if (!this.selected_wo) await this.$store.dispatch('clearWorkOrderAttendance');

        },

        autoSelectWO() {
            if (this.user.role == "ADMIN"){
                return;
            }

            if (!this.preWorkOrdersFilterWorking) return;

            if (this.preWorkOrdersFilterWorking.length == 1) {
                this.selected_wo = this.preWorkOrdersFilterWorking[0].work_order_obj;
                this.loadWorkOrderWorkers();

            } else if (this.preWorkOrdersFilterWorking.length > 1 && this.selected_wo) {
                this.selected_wo.id = this.preWorkOrdersFilterWorking.anyMatch(x => x.id == this.selected_wo.id);
            } else {
                this.selected_wo = null;
            }
        },

        woChanged() {
            if (this.selected_wo == -1)
                this.loadAllWorkOrderWorkers()
            else
                this.loadWorkOrderWorkers()
        },

        async loadPreWorkOrders() {
            try {

                if (this.user.role != "ADMIN") {
                    let result = await EmployeesService.getPreWorkOrdersForEmployee({id: this.user.id, date: this.selected_date});
                    this.preWorkOrders = result.data;

                    // QUIICK FIX
                    this.preWorkOrders.forEach(element => {
                        element.work_order_obj = element.work_order;
                    });
                }

                this.autoSelectWO();
            } catch (error) {
                console.log(error);
            }

        },

        async loadAllWorkOrders() {
            await this.$store.dispatch('loadAllWorkOrders', this.workOrderForm).catch((error) => {
                alert("neuspesno ucitavanje radnih naloga");
                console.log(error);
            });

        },

        async loadWorkOrderWorkers() {
            let data = {
                'work_order_id': this.selected_wo.id, 
                'date': this.selected_date
            };
                            
            await this.$store.dispatch('getWorkOrderAttendance', data).catch((error) => {
                console.log(error)
                this.$toasted.show("Neuspešno učitavanje radnika")   
            })

            this.workAttendanceEmployees.forEach(element => {
                Vue.set(element, 'edit_mode', false)
                Vue.set(element, 'cateringType', null)

                if (!element.work_attendance) element.work_attendance = this.newWorkAttendanceObject(element.id);

            });

            this.updateTimeSheets();
        },

        async loadAllWorkOrderWorkers() {
            let data = {
                'date': this.selected_date
            };
            
            await this.$store.dispatch('getAllWorkOrderAttendance', data).catch((error) => {
                console.log(error)
                this.$toasted.show("Neuspešno učitavanje radnika")   
            })

            this.workAttendanceEmployees.forEach(element => {
                Vue.set(element, 'edit_mode', false)
                Vue.set(element, 'cateringType', null)
                if (!element.work_attendance) element.work_attendance = this.newWorkAttendanceObject(element.id);

            });

            this.updateTimeSheets();
        },

        sortWorkers(data) {
            return data.sort((b, a) => {
                if (!a.work_attendance) return 1;
                if (!b.work_attendance) return -1;
                return UtilService.convertMoment(a.work_attendance?.time_start, "HH:ss").diff(UtilService.convertMoment(b.work_attendance?.time_start, "HH:ss"))
            });
        },

        getLowerRoundHour() {
            return UtilService.roundLower30min(UtilService.getMoment()).format("HH:mm")
        },

        getCurrentDate() {
            return UtilService.getMoment().format("HH:mm")
        },

        getUpperRoundHour() {
            return UtilService.roundUpper30min(UtilService.getMoment()).format("HH:mm")
        },

        formatTime(time) {
            return UtilService.convertMoment(time, "HH:mm:ss").format("HH:mm");
        },

        getCurrentWAObject() {
            return {
                "id": null,
                "date": this.selected_date,
                "time_start": this.autoStartTime,
                "time_end": null,
                "employee_id": null,
                "catering": false,
            }
        },

        newWorkAttendanceObject(employee_id) {
            return {
                "id": this.fakeId++,
                "fakeId": true,
                "date": this.selected_date,
                "employee_id": employee_id,
                "employee": employee_id,
                "timesheet": []
            }
        },

        // async workerArrived(worker) {
        //     let wa = this.getCurrentWAObject();
        //     wa.date = this.selected_date;
        //     wa.employee_id = worker.id;
        //     wa.work_order = worker.pwo.work_order;

        //     wa = await this.saveWorkAttendance(wa);
        //     worker.work_attendance = wa.data;

        // },

        async workerArrivedWithLocation(worker, cateringType="OWN", catering_service_id=null) {
            if (!cateringType) {
                navigator.geolocation.getCurrentPosition((locationData) => {this.workerArrived(worker, locationData)}, this.geoDeclined);
            } else {
                if (cateringType == "OWN") catering_service_id = null
                navigator.geolocation.getCurrentPosition((locationData) => {this.workerArrivedPlusCatering(worker, catering_service_id, locationData)}, this.geoDeclined);
            }

        },

        async workerArrived(worker, locationData=null) {
            let ts = this.newEmptyTimeSheet();
            ts.time_start = this.autoStartTime;
            ts.work_order_id = worker.pwo.work_order
            ts.employee_id = worker.id;

            if (locationData) {
                let [, radius] = this.checkLocation(worker.pwo.work_order_obj, locationData.coords)
                ts.longitude_start = locationData.coords.longitude
                ts.latitude_start = locationData.coords.latitude
                ts.outside_distance_start = radius
                ts.start_signed_by = this.user.id
            }

            let wa = JSON.parse(JSON.stringify(worker.work_attendance))
            wa.timesheet.push(ts);
            wa = await this.saveWorkAttendance(wa);

            worker.work_attendance = wa.data;
            this.updateTimeSheets();

        },


        async workerArrivedPlusCateringWithLocation(worker, catering_service) {
            navigator.geolocation.getCurrentPosition((locationData) => {this.workerArrivedPlusCatering(worker, catering_service, locationData)}, this.geoDeclined);
        },

        async workerArrivedPlusCatering(worker, catering_service, locationData=null) {
            let ts = this.newEmptyTimeSheet();
            ts.time_start = this.autoStartTime;
            ts.work_order_id = worker.pwo.work_order
            ts.employee_id = worker.id;
            
            if (locationData) {
                let [, radius] = this.checkLocation(worker.pwo.work_order_obj, locationData.coords)
                ts.longitude_start = locationData.coords.longitude
                ts.latitude_start = locationData.coords.latitude
                ts.outside_distance_start = radius
                ts.start_signed_by = this.user.id
            }

            let wa = JSON.parse(JSON.stringify(worker.work_attendance))
            wa.timesheet.push(ts);
            wa = await this.saveWorkAttendance(wa);
            this.addCatering(worker, catering_service)

            worker.work_attendance = wa.data;
            this.updateTimeSheets();

        },

        async addCatering(worker, catering_service) {

            let data = {
                employee_id: worker.id,
                date: this.selected_date,
                time: this.autoStartTime,
                work_order_id: worker.pwo.work_order,
                catering_service_id: catering_service,
                provided_by: "COMPANY"
            }

            if (!catering_service) data.provided_by = "OWN"


            CateringService.addCatering(data).then(result => {
                console.log(result);
                this.$toasted.show("+ obrok za radnika")   
            })
        },



        async workerLeavedWithLocation(worker) {
            navigator.geolocation.getCurrentPosition((locationData) => {this.workerLeaved(worker, locationData)}, this.geoDeclined);
        },

        async workerLeaved(worker, locationData=null) {
            let ts = JSON.parse(JSON.stringify(worker.work_attendance.timesheet.at(-1)))
            ts.time_end = this.getLeaveTime(worker);

            if (locationData) {
                let [, radius] = this.checkLocation(worker.pwo.work_order_obj, locationData.coords)
                ts.longitude_end = locationData.coords.longitude
                ts.latitude_end = locationData.coords.latitude
                ts.outside_distance_end = radius
                ts.end_signed_by = this.user.id
            }
            
            await this.leaveTimesheet(ts);

            let wa = await this.getWorkAttendance(worker.work_attendance);
            worker.work_attendance = wa.data;
            this.updateTimeSheets();

        },

        // async workerLeaved(worker) {
        //     let wa = worker.work_attendance;

        //     let timesheetIndex = wa.timesheet?.findIndex(x => x.time_end == undefined)
        //     let timesheetId = wa.timesheet[timesheetIndex].id;


        //     let data = {
        //         id: timesheetId,
        //         time_end: this.getLeaveTime(worker)
        //     }

        //     let ts = undefined;
        //     try {
        //         ts = await this.putTimeSheet(data);
        //         if (ts) wa.timesheet[timesheetIndex] = ts.data;
        //     } catch {
        //         alert("Neuspesno cuvanje odlaska")
        //     }

        //     this.$set(worker.work_attendance, 'timesheetIndex', wa.timesheets);
        //     this.updateTimeSheets();


        //     return ts;

        // },


        async saveWorkerArrived(worker, createCatering=false) {
            let wa = await this.saveWorkAttendance(worker.work_attendance);
            worker.work_attendance = wa.data;
            if (createCatering) {
                this.addCatering(worker, createCatering.catering_service_id)
            }

            worker.edit_mode = false;
            this.updateTimeSheets()


        },

        async discartEdit(worker) {
            if (worker.work_attendance.id == null) {
                worker.edit_mode = false;
                worker.work_attendance = this.newWorkAttendanceObject(worker.id);
            } else {
                let wa = await this.getWorkAttendance(worker.work_attendance);
                worker.work_attendance = wa.data;
                worker.edit_mode = false;
            }

            this.updateTimeSheets();
        },

        deleteWorkerArrived(worker) {
            let confirmation = confirm("Da li ste sigurni da " + UtilService.userFullName(worker) + " nije došao")
            if (!confirmation) return

            EmployeesService.deleteWorkAttendance(worker.work_attendance)
            worker.edit_mode = false;
            worker.work_attendance = null;
            
        },

        deleteTimesheet(worker, data) {
            if (!data.fakeId) {
                if (!data.deleted) {
                    let confirmation = confirm("Da li ste sigurni da " + UtilService.userFullName(worker) + " nije došao")
                    if (!confirmation) return
                }
                data.deleted = !data.deleted;

            } else {
                worker.work_attendance.timesheet = worker.work_attendance.timesheet.filter(x=> x.id != data.id);
            }
            
            this.editedTimesheet(worker)

            this.updateTimeSheets();
        },

        addTimesheet(worker) {
            let ts = this.newEmptyTimeSheet();
            ts.work_order = worker.pwo.work_order
            ts.employee_id = worker.id;
            // ts.work_attendance = worker.work_attendance.id;

            worker.work_attendance.timesheet.push(ts);
            worker.edit_mode = true;
            this.updateTimeSheets();
        },

        async workerLeft(worker) {
            let wa = JSON.parse(JSON.stringify(worker.work_attendance))
            wa.time_end = this.this.startTime;
            wa.employee_id = worker.id

            wa = await this.saveWorkAttendance(wa);
            worker.work_attendance = wa.data;

        },

        editedTimesheet(worker) {
            Vue.set(worker.work_attendance, 'edited', true)
        },

        prepareTimesheetForSave(timesheet) {
            if (!timesheet.work_order_id) timesheet.work_order_id = timesheet.work_order
            delete timesheet.work_order;
            delete timesheet.work_order_obj;

            if (!timesheet.quota_id) timesheet.quota_id = timesheet.quota
            delete timesheet.quota;
            delete timesheet.quota_obj;

            timesheet.work_attendance_id = timesheet.work_attendance;
            delete timesheet.work_attendance

            timesheet.approved_by_id = timesheet.approved_by;
            delete timesheet.approved_by

            delete timesheet.employee_id

            return timesheet
        },

        async saveWorkAttendance(work_attendance) {
            work_attendance = JSON.parse(JSON.stringify(work_attendance))

            if (work_attendance.fakeId) work_attendance.id = null;
            work_attendance.timesheet.forEach(ts => {
                if (ts.fakeId) ts.id = null;
                ts = this.prepareTimesheetForSave(ts)
            });
            try{
                let result = await EmployeesService.postWorkAttendance(work_attendance);
                return result;
            } catch (error) {
                console.log(error);
                this.$toasted.show("Neuspešno čuvanje radnika")   
            }
        },


        async leaveTimesheet(timesheet) {
            try{
                let result = await EmployeesService.timesheetLeave(timesheet);
                return result;
            } catch (error) {
                console.log(error);
                this.$toasted.show("Neuspešno čuvanje radnog vremena")   
            }
        },

        
        async putTimeSheet(timesheet) {
            try{
                let result = await WorkAttendanceService.putTimesheet(timesheet);
                return result;
            } catch (error) {
                console.log(error);
                this.$toasted.show("Neuspešno čuvanje radnika")   
            }
        },


        async getWorkAttendance(work_attendance) {
            try{
                let result = await EmployeesService.getWorkAttendance(work_attendance);
                return result;
            } catch (error) {
                console.log(error);
                this.$toasted.show("Neuspešno dobavljanje radnika")   
            }
        },

        employeeLeavedButton(worker) {
            Vue.set(worker, 'edit_mode', true)
            worker.work_attendance.time_end = this.autoStartTimeLower;
            Vue.set(worker.work_attendance, 'edited', true);
        },

        editWorkAttendance(worker) {
            if (worker.work_attendance.timesheet.filter(x => !x.deleted).length === 0) this.create_wa_edit(worker);

            Vue.set(worker, 'edit_mode', true);
            this.updateTimeSheets();
        },

        create_wa_edit(worker) {
            let ts = this.newEmptyTimeSheet();
            ts.employee_id = worker.id;
            ts.edited = true;
            ts.work_order = worker.pwo.work_order;

            worker.work_attendance.timesheet.push(ts);
        },

        newEmptyTimeSheet() {
            return {"id":this.fakeId++,"fakeId":true,"work_order_obj":null,"quota":null,"quota_obj":null,"status":"APPROVED","quota_value":null,"time_start":null,"time_end":null,"location_time_start":null,"location_time_end":null,"longitude_start":null,"latitude_start":null,"longitude_end":null,"latitude_end":null,"outside_distance_start":0,"outside_distance_end":0,"deleted":false,"work_attendance":null,"work_order":null,"approved_by":null,"start_signed_by":null,"end_signed_by":null,"employee_id":null}
        },

        updateTimeSheets() {
            let timesheets = []
            this.workAttendanceEmployees.forEach(wa => {
                wa.employee_id = wa.id;
                wa?.work_attendance?.timesheet?.forEach(ts => {
                    
                    ts.employee_id = wa.id;
                    timesheets.push(ts);

                });

                if (wa?.work_attendance?.timesheet?.length > 0) {
                    wa.time_start = wa.work_attendance.timesheet[0].time_start
                    wa.time_end = wa.work_attendance.timesheet.at(-1).time_end
                }

                if (!wa.work_attendance) wa.work_attendance = {}
                if (!wa.work_attendance.timesheet) wa.work_attendance.timesheet = []
                let addRowBtn = this.newEmptyTimeSheet();
                addRowBtn.addRow = true;
                addRowBtn.employee_id = wa.employee_id;
                timesheets.push(addRowBtn);
                // wa.work_attendance.timesheet.push({
                //     addmore: true
                // })

            });

            this.saveExpandedRows();
            this.$refs.workAttendanceGrid.ej2Instances.childGrid.dataSource = timesheets;
            
            this.$refs.workAttendanceGrid.ej2Instances.detailRowModule.childRefs.forEach(x=>{
                x.dataSource = timesheets
                x.refresh()
            })
            
            return timesheets
        },

        refreshDetailedTables() {
            this.$refs.workAttendanceGrid.ej2Instances.detailRowModule.childRefs.forEach(childRef => {childRef.refresh()})

        },

        saveExpandedRows() {
            let expandedRowIndex = []
            let expandedRows = this.$refs.workAttendanceGrid.ej2Instances.element.querySelectorAll(".e-detailrowexpand"); 
            expandedRows.forEach(row => {
                expandedRowIndex.push(parseInt(row.parentElement.getAttribute("aria-rowindex")));
            });
            this.expandedRowIndex = expandedRowIndex;

        },

        startMuster() {
            this.musterWorkers = this.filterWorkers
            this.$refs.musterModal.start();
            this.musterModal = new Modal(document.getElementById('musterModal'));
            this.musterModal.toggle();

        },
        getLeaveTime(worker) {
            let start_time = worker.work_attendance.timesheet?.[0].time_start?.slice(0,5)
            if (this.autoStartTime < start_time) return start_time
            return this.autoStartTime
        },

        workAttendanceDB(event) {
            console.log(event)
            const worker = event.rowData;
            this.editWorkAttendance(worker);
        },

        openModalAttendanceType() {
            this.modalAttendanceType.show();
        },

        openModalAttendanceMap() {
            this.modalAttendanceMap.show();
        },

        openModalEmployeeCatering(employee) {
            this.selectedCateringEmployee = employee
            this.modalEmployeeCatering.show();
        },


        dataBound() {
            this.expandedRowIndex.forEach(index => {
                this.$refs.workAttendanceGrid.ej2Instances.detailRowModule.expand(index); 
            });
            this.$refs.workAttendanceGrid.autoFitColumns();
        },

        printGrid() {
            // this.tempData = [...this.tempData];
            // this.$refs.workAttendanceGrid.ej2Instances.childGrid.dataSource = [];
            // this.$refs.workAttendanceGrid.ej2Instances.refresh();
            
            // this.childGrid.dataSource = [];
            console.log(this.$refs.workAttendanceGrid);
            // console.log(this.timesheets);
        },

        enableEdit() {
            this.editMode = true;
        },

        actionFailure(error) {
            console.log(error)
        },


        enableGeo() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(() => {}, this.geoDeclined);
            } else {
                alert("Nije moguce izvrsiti samostalnu evidencijju.");
            }
        },

        geoDeclined(error) {
            console.log(error);
            if (error.code == error.PERMISSION_DENIED)
                alert("NEUSPEŠNA EVIDENCIJA! Morate dozvoliti lokaciju ili probajte pomoću drugog internet pregledača, Kontaktirajte administratora za uputstvo");
            else if (error.code == error.POSITION_UNAVAILABLE) 
                alert("NEUSPEŠNA EVIDENCIJA! Nema informacija o lokaciji, Kontaktirajte administratora za evidenciju");
            else
                alert("NEUSPEŠNA EVIDENCIJA! Neuspešna evidencija, kotaktiraje administratora za evidenciju");
        },

        checkLocation(work_order, location) {
            if (work_order.location_longitude && work_order.location_latitude, work_order.location_radius) {
                let lonlatCurrent = [location.longitude, location.latitude];
                let lonlatDestination = [work_order.location_longitude, work_order.location_latitude];
                let radius = this.distanceBetweenPoints(lonlatCurrent, lonlatDestination);
                let radius_m = Math.round(this.realDistanceBetweenPoints(lonlatCurrent, lonlatDestination))
                if (radius > work_order.location_radius) return [false, radius_m - work_order.location_radius];
                return [true, 0];
            }
            return [true, 0];
        },

        distanceBetweenPoints(lonlat1, lonlat2){
            let a = fromLonLat(lonlat1)
            let b = fromLonLat(lonlat2)
            var line = new LineString([a, b]);

            return Math.round(line.getLength() * 100) / 100;
        },


        realDistanceBetweenPoints(lonlat1, lonlat2){
            let a = fromLonLat(lonlat1)
            let b = fromLonLat(lonlat2)
            var line = new LineString([a, b]);

            return getLength(line)
        },

    },

    created() {
        if (this.user.role == "ADMIN"){
            this.loadAllWorkOrders();
            this.selected_wo = -1;
            this.woChanged();
        } else {
            this.loadPreWorkOrders()
        }

        this.$store.dispatch('loadAllCateringServices');
    },

    mounted() {
        this.musterModal = new Modal(document.getElementById('musterModal'));
        this.modalAttendanceType = new Modal(document.getElementById('modalAttendanceType'));
        this.modalAttendanceMap = new Modal(document.getElementById('modalAttendanceMap'));
        this.modalEmployeeCatering = new Modal(document.getElementById('modalEmployeeCatering'));
    }
}
</script>

<style>
    .work-attendance-grid > .e-gridcontent > .e-content {
        height: calc(100vh - 150px) !important;
    }

    .work-attendance-grid .e-gridcontent > .e-content .usernameTemplate a {
        color: #222;
        text-decoration: none;
        font-size: 1.5em;
    }
    .work-attendance-grid .e-gridcontent > .e-content .role-manager  {
        font-weight: bold;
    }
    
    .work-attendance-grid > .e-gridcontent > .e-content > .e-table > tbody > tr > td  {
        padding: 0.2em 1em;
    }
    
    .work-attendance-grid > .e-gridcontent > .e-content > .e-table > tbody > tr > td .cell-deleted  {
        text-decoration: line-through;
        background: #777;
    }
    

    .work-attendance-grid > .e-gridcontent > .e-content > .e-table > tbody > tr:hover > td .cell-deleted  {
        background: #777 !important;
    }

    .work-attendance-grid .template-avatar{
        height: 32px !important;
        width: 32px !important;
    }
    
    .work-attendance-grid .e-detailcell{
        background: rgb(203, 203, 203);
    }
    
    .work-attendance-grid .e-detailcell:after{
        content: '';
        display: block;
        height: 2em;
    }

</style>

<style scoped>

.table-title {
    padding-bottom: 15px;
    background: #299be4;
    padding: 16px 30px;
    margin: 0 -25px 10px;
    border-radius: 3px 3px 0 0;
}

.table-title h2{
    margin: 5px 0 0;
    font-size: 1.8em;
}

.table-title .white-btn {
    height: 100%;    
    background: #fff;
    width: 100%;
}

.table-title .btn:hover, .table-title .btn:focus {
    color: #566787;
    background: #f2f2f2;
}

.table-title .btn .btn-title {
    margin-top: 20px;
}

.table-wrapper {
    min-width: 1000px;
    background: #fff;
    padding: 20px 25px;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}


.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
}
table.table thead {
    font-size: 0.8em
}

.h4 {
    font-style: bold;
}

.card-img-top {
    width: 100%;
    height: 4vw;
    min-height: 150px;
    object-fit: cover;
}

#cards .card {
    min-height: 100px;
}

#cards .card-body {
    background: #ffffffa7;
    position: absolute;
    bottom: 0;
    width: 100%;
}

#cards .card:hover .card-body {
    background: #ffffffef;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.time-close {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    background: #fff;
}

.list-group-item.workers-list.not-arrived {
    background: #bbbbbb;
}


.list-group-item.workers-list.arrived, .list-group-item.workers-list.arrived .worker-name {
    font-weight: bold !important;
}

.hours-view {
    font-size: 1.5em;
}

.list-group-item.workers-list.arrived .catering-info {
    font-size: 1.5em;
    margin-right: 0.2em;
}

</style>