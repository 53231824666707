<template>
    <div>
        <template v-if="!isAbsence || data.work_attendance">
            <template v-if="column == 'CMD'">

                    <div v-if="data.edit_mode" class="input-group partialy-input-group justify-content-end">

                        <button v-show="data.work_attendance.edited" type="button" class="btn btn-success" @click="saveEditButton"><font-awesome-icon icon="check"></font-awesome-icon></button>
                        <button :disabled="!isAdmin" v-if="data.work_attendance.id" type="button" class="btn" @click="$parent.$parent.deleteWorkerArrived(data)"><font-awesome-icon icon="trash"></font-awesome-icon></button>
                        <button type="button" class="btn" @click="$parent.$parent.discartEdit(data)"><font-awesome-icon icon="times"></font-awesome-icon></button>
                    </div>

                    <template v-else>
                        <div class="justify-content-end">
                            <button type="button" class="btn" @click="openEditButton"><font-awesome-icon icon="pen"></font-awesome-icon></button>
                        </div>
                    </template>

            </template>

    <!-- ----------------------------------------- -->

            <div class="time-label" v-if="column == 'START'">
                <template v-if="!data.edit_mode">
                    <div v-if="!arrived">
                        <div v-if="isToday && $parent.$parent.editMode" class="from-floating">
                            <select v-model="cateringType" @change="changedCateringType" class="form-select catering-select" name="cateringType" id="cateringTypeSelect">
                                <option :value="null" disabled>Obrok</option>
                                <option value="OWN">Samostalan</option>
                                <option value="COMPANY" v-if="workOrderCatering">Ketering {{ workOrderCatering }}</option>
                                <option :value="null">Bez obroka</option>
                                <!-- <option value="COMPANY" v-if="workOrderCatering">Ketering {{ workOrderCatering }}</option> -->
                            </select>
                        </div>
                        
                        <span v-else>
                            <font-awesome-icon icon="times"/>
                        </span>
                    </div>
                    <div v-if="arrived">
                        <h4>
                            <a target="_blank" :href="'https://www.google.com/maps/search/?api=1&query=' + firstTimesheet.latitude_start + ',' + firstTimesheet.longitude_start" v-if="firstTimesheet.longitude_start" :class="getSelfSignDistanceClass(firstTimesheet.outside_distance_start)" type="button" class="btn"><font-awesome-icon size="2x" icon="map-marker-alt"></font-awesome-icon></a>
                            {{sliceTime(firstTimesheet.time_start)}}
                        </h4>
                    </div>
                </template>
                <template v-else>
                    <input :disabled="!isAdmin && firstTimesheet && firstTimesheet.time_start && data.work_attendance.id" type="time" class="form-control" :max="firstTimesheet.time_end" v-model="firstTimesheet.time_start" aria-label="Od" @change="changedStartTime">
                </template>
            </div>

    <!-- ----------------------------------------- -->
        
            <div class="time-label" v-if="column == 'END'">
                <template v-if="!data.edit_mode">

                    <template v-if="arrived">
                        <template v-if="finished">
                            <div class="position-relative">

                                <span class="position-absolute translate-middle top-0 start-100 badge rounded-pill bg-danger mt-1" v-if="activeTimesheets.length > 1">{{ activeTimesheets.length }}</span>

                                <h4>
                                    <a target="_blank" :href="'https://www.google.com/maps/search/?api=1&query=' + lastTimesheet.latitude_end + ',' + lastTimesheet.longitude_end"
                                    v-if="lastTimesheet.longitude_end" type="button" class="btn"
                                    :class="getSelfSignDistanceClass(lastTimesheet.outside_distance_end)"
                                    >
                                        <font-awesome-icon size="2x" icon="map-marker-alt"></font-awesome-icon>
                                    </a>
                                    {{sliceTime(lastTimesheet.time_end)}}
                                </h4>
                            </div>
                        </template>
                        <button v-else-if="isToday && $parent.$parent.editMode && !isJustAssigned" type="button" class="btn btn-primary" @click="$parent.$parent.workerLeavedWithLocation(data)">Odlazak</button>
                    </template>
                    <template v-else>
                        <div v-if="isToday" class="btn-group">
                            <template v-if="$parent.$parent.editMode">
                                <button type="button" class="btn btn-primary" @click="$parent.$parent.workerArrivedWithLocation(data, data.cateringType, workOrderCatering)">Dolazak</button>
                                <!-- <button type="button" class="btn btn-outline-primary btn" @click="$parent.$parent.workerArrivedPlusCateringWithLocation(data)"><font-awesome-icon icon="hamburger"></font-awesome-icon></button> -->
                            </template>
                        </div>
                        
                    </template>

                </template>
                <template v-else>
                    <input :disabled="!isAdmin && lastTimesheet.time_end && !endInEdit" type="time" class="form-control" :min="lastTimesheet.time_start" v-model="lastTimesheet.time_end" aria-label="Do" @change="changedEndTime">
                </template>

            </div>

    <!-- ----------------------------------------- -->
            <template v-if="column == 'CATERING'">
                    <template v-if="data.edit_mode && data.work_attendance && !arrivedSaved">
                        <div class="from-floating">
                            <select v-model="cateringType" @change="changedCateringType" class="form-select catering-select" name="cateringType" id="cateringTypeSelect">
                                <option :value="null" disabled>Obrok</option>
                                <option value="OWN">Samostalan</option>
                                <option value="COMPANY" v-if="workOrderCatering">Ketering {{ workOrderCatering }}</option>
                                <option :value="null">Bez obroka</option>
                                <!-- <option value="COMPANY" v-if="workOrderCatering">Ketering {{ workOrderCatering }}</option> -->
                            </select>
                        </div>

                    </template>
                        
                    <template v-else>
                        <button v-if="data.catering && data.catering.length > 0" class="position-relative link-dark btn btn-sm p-0" @click="showCateringModal(data)">
                            <span class="position-absolute translate-middle top-0 start-100 badge rounded-pill bg-danger mt-1" v-if="data.catering.length > 1">{{data.catering.length}}</span>
                            <font-awesome-icon size="2x" icon="hamburger" />
                        </button>
                    </template>

            </template>
        </template>

        <template v-if="isAbsence">
            <template v-if="column == 'CMD'">
                <h5><font-awesome-icon icon="house-user"></font-awesome-icon> {{absenceTypeFormat(data.absence)}}</h5>

            </template>

        </template>
    </div>
</template>

<script>
    import UtilService from '@/service/UtilService.js';
    import {mapGetters} from 'vuex'


    export default {
        props: ['column', 'quickAction'],

        data: function() {
            return {
                endInEdit: false,
                startInEdit: false,
                data: {},
                cateringType: null,
            }
        },

        

        computed: {
            ...mapGetters([
                'isAdmin',
                'settings'
            ]),

            arrivedSaved() {
                let firstTimesheet = this.firstTimesheet
                return firstTimesheet?.time_start != undefined && (firstTimesheet?.fakeId == false || firstTimesheet?.fakeId == undefined);
            },

            arrived() {
                return this.firstTimesheet?.time_start != undefined;
            },

            finished() {
                return this.lastTimesheet?.time_end != undefined;
            },

            isToday() {
                return this.$parent.$parent.isToday;
            },

            isAbsence() {
                return this.data.absence != undefined;
            },


            activeTimesheets() {
                return this.data.work_attendance.timesheet.filter(x => !x.deleted)
            },

            firstTimesheet() {
                return this.activeTimesheets?.at(0)
            },

            lastTimesheet() {
                return this.activeTimesheets?.at(-1)
            },

            workOrderCatering() {
                let wo = this.data.pwo.work_order_obj
                return wo.catering_service_id
            },

            isCateringAvailable() {
                if (!this.settings) return false


                let assign_end_time = this.settings.find(a => a.identifier == "catering__assign_end_time").value

                let current_time = (new Date()).toLocaleTimeString('en-US', { hourCycle: 'h23' }); 
                if (assign_end_time >= current_time) return true
                return false
            },

            isJustAssigned() {
                return this.sliceTime(this.firstTimesheet.time_start) == this.$parent.$parent.autoStartTime
            }

        },

        methods: {
            formatDate : UtilService.formatDate,
            formatDateTime : UtilService.formatDateTime,
            absenceTypeFormat: UtilService.absenceTypeFormat,


            sliceTime(time) {
                return time.slice(0,5)
            },

            getSelfSignDistanceClass(distance) {
                let data = {}
                if (distance === null) data["no-data"] = true;
                else if (distance === 0) data["in-area"] = true;
                else if (distance < 200) data["close-area"] = true;
                else if (distance >= 200) data["away"] = true

                return data
            },

            changedEndTime() {
                this.endInEdit = true;
                this.$parent.$parent.editedTimesheet(this.data)
            },

            changedStartTime() {
                this.startInEdit = true;
                this.$parent.$parent.editedTimesheet(this.data)
            },

            saveEditButton() {
                this.endInEdit = false;
                this.startInEdit = false;

                let catering = false
                if (this.data.cateringType && !this.arrivedSaved) {
                    catering = {
                        "provided_by": this.data.cateringType,
                        "catering_service_id": this.workOrderCatering,
                    }
                    
                }

                this.$parent.$parent.saveWorkerArrived(this.data, catering)
            },

            openEditButton() {
                this.$parent.$parent.editWorkAttendance(this.data)
            },

            showCateringModal() {
                this.$parent.$parent.openModalEmployeeCatering(this.data)
            },

            changedCateringType() {
                this.data.cateringType = this.cateringType
            }

        },

        mounted() {
            this.data = this.$parent.$parent.workAttendanceEmployees.find(x=> x.id == this.data.id);
            
            if (!this.isCateringAvailable && this.workOrderCatering){
                this.cateringType = "COMPANY"
            } else {
                this.cateringType = "OWN"
            }
            this.data.cateringType = this.cateringType


        }
    }
</script>

<style scoped>
    .away {
        background: red;
    }

    .close-area {
        background: yellow;
    }

    .in-area {
        color: greenyellow;
    }
    
    .time-label {
        min-width: 70px;
        text-align: left;
    }

    .catering-select {
        min-width: 120px;
        text-align: right;
    }
</style>